<template>
  <validation-observer ref="validationObserver">
    <b-card-form title="Customer Support Infomation">
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >
          <b-form-group
            label="Title English"
            label-for="customerSupportTitleEn"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Title English"
            >
              <b-form-input
                id="customerSupportTitleEn"
                v-model="data.customerSupportTitleEn"
                :state="errors.length > 0 ? false : null"
                placeholder="Title English"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
          dir="rtl"
        >
          <b-form-group
            label="Title Arabic"
            label-for="customerSupportTitleAr"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Title Arabic"
            >
              <b-form-input
                id="customerSupportTitleAr"
                v-model="data.customerSupportTitleAr"
                :state="errors.length > 0 ? false : null"
                placeholder="Title Arabic"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >
          <b-form-group
            label="Subtitle English"
            label-for="customerSupportSubTitleEn"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Subtitle English"
            >
              <b-form-input
                id="customerSupportSubTitleEn"
                v-model="data.customerSupportSubTitleEn"
                :state="errors.length > 0 ? false : null"
                placeholder="Subtitle English"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
          dir="rtl"
        >
          <b-form-group
            label="Subtitle Arabic"
            label-for="customerSupportSubTitleAr"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Subtitle Arabic"
            >
              <b-form-input
                id="customerSupportSubTitleAr"
                v-model="data.customerSupportSubTitleAr"
                :state="errors.length > 0 ? false : null"
                placeholder="Subtitle Arabic"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >
          <b-form-group
            label="Email"
            label-for="customerSupportEmail"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Email"
            >
              <b-form-input
                id="customerSupportEmail"
                v-model="data.customerSupportEmail"
                :state="errors.length > 0 ? false : null"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >
          <b-form-group
            label="Phone Number"
            label-for="customerSupportPhone"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Phone Number"
            >
              <b-form-input
                id="customerSupportPhone"
                v-model="data.customerSupportPhone"
                :state="errors.length > 0 ? false : null"
                placeholder="Phone Number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-form>
    <b-card-form title="FAQs">
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >
          <b-form-group
            label="Title English"
            label-for="faqsTitleEn"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Title English"
            >
              <b-form-input
                id="faqsTitleEn"
                v-model="data.faqsTitleEn"
                :state="errors.length > 0 ? false : null"
                placeholder="Title English"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
          dir="rtl"
        >
          <b-form-group
            label="Title Arabic"
            label-for="faqsTitleAr"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Title Arabic"
            >
              <b-form-input
                id="faqsTitleAr"
                v-model="data.faqsTitleAr"
                :state="errors.length > 0 ? false : null"
                placeholder="Title Arabic"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >
          <b-form-group
            label="Subtitle English"
            label-for="faqsSubTitleEn"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Subtitle English"
            >
              <b-form-input
                id="faqsSubTitleEn"
                v-model="data.faqsSubTitleEn"
                :state="errors.length > 0 ? false : null"
                placeholder="Subtitle English"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
          dir="rtl"
        >
          <b-form-group
            label="Subtitle Arabic"
            label-for="faqsSubTitleAr"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Subtitle Arabic"
            >
              <b-form-input
                id="faqsSubTitleAr"
                v-model="data.faqsSubTitleAr"
                :state="errors.length > 0 ? false : null"
                placeholder="Subtitle Arabic"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >
          <b-form-group
            label="FAQs Linked Page Url"
            label-for="faqsLinkedPageUrl"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="FAQs Linked Page Url"
            >
              <b-form-input
                id="faqsLinkedPageUrl"
                v-model="data.faqsLinkedPageUrl"
                :state="errors.length > 0 ? false : null"
                placeholder="FAQs Linked Page Url"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-form>
    <b-card-form title="Social Media ">
      <b-row
        v-if="data.linkedInInfo"
        align-v="end"
      >
        <b-col cols="1">
          <b-img
            style="width: 32px; height: 32px"
            fluid
            :src="data.linkedInInfo.iconUrl"
            alt=" "
          />
        </b-col>
        <b-col>
          <label for="wildcard">Linkedin Icon</label>
          <b-form-file
            ref="file"
            v-model="image"
            type="file"
            accept="image/*"
            :disabled="!data.linkedInInfo.enabled"
            @input="upload(image, 'linkedInInfo')"
          />
        </b-col>
        <b-col cols="1">
          <b-button
            variant="outline-primary"
            title="Clear Image"
            :disabled="!data.linkedInInfo.enabled"
            @click="clearImage('linkedInInfo')"
          >
            <b-icon
              icon="eraser"
              aria-hidden="true"
            />
          </b-button>
        </b-col>
        <b-col>
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Linkedin Link"
          >
            <label for="wildcard">Linkedin Link</label>
            <b-form-input
              id="linkedInInfoPageUrl"
              v-model="data.linkedInInfo.pageUrl"
              :disabled="!data.linkedInInfo.enabled"
              :state="errors.length > 0 ? false : null"
              placeholder="Linkedin Link"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col cols="1">
          <b-form-checkbox
            v-model="data.linkedInInfo.enabled"
            checked="true"
            name="check-button"
            switch
            inline
          />
        </b-col>
      </b-row>
      <b-row
        v-if="data.twitterInfo"
        align-v="end"
      >
        <b-col cols="1">
          <b-img
            style="width: 32px; height: 32px"
            fluid
            :src="data.twitterInfo.iconUrl"
            alt=" "
          />
        </b-col>
        <b-col>
          <label for="wildcard">Twitter Icon</label>
          <b-form-file
            ref="file"
            v-model="image"
            type="file"
            accept="image/*"
            :disabled="!data.twitterInfo.enabled"
            @input="upload(image, 'twitterInfo')"
          />
        </b-col>
        <b-col cols="1">
          <b-button
            variant="outline-primary"
            title="Clear Image"
            :disabled="!data.twitterInfo.enabled"
            @click="clearImage('twitterInfo')"
          >
            <b-icon
              icon="eraser"
              aria-hidden="true"
            />
          </b-button>
        </b-col>
        <b-col>
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Twitter Link"
          >
            <label for="wildcard">Twitter Link</label>
            <b-form-input
              id="twitterInfoPageUrl"
              v-model="data.twitterInfo.pageUrl"
              :disabled="!data.twitterInfo.enabled"
              :state="errors.length > 0 ? false : null"
              placeholder="Twitter Link"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col cols="1">
          <b-form-checkbox
            v-model="data.twitterInfo.enabled"
            checked="true"
            name="check-button"
            switch
            inline
          />
        </b-col>
      </b-row>
      <b-row
        v-if="data.facebookInfo"
        align-v="end"
      >
        <b-col cols="1">
          <b-img
            style="width: 32px; height: 32px"
            fluid
            :src="data.facebookInfo.iconUrl"
            alt=" "
          />
        </b-col>
        <b-col>
          <label for="wildcard">Facebook Icon</label>
          <b-form-file
            ref="file"
            v-model="image"
            type="file"
            accept="image/*"
            :disabled="!data.facebookInfo.enabled"
            @input="upload(image, 'facebookInfo')"
          />
        </b-col>
        <b-col cols="1">
          <b-button
            variant="outline-primary"
            title="Clear Image"
            :disabled="!data.facebookInfo.enabled"
            @click="clearImage('facebookInfo')"
          >
            <b-icon
              icon="eraser"
              aria-hidden="true"
            />
          </b-button>
        </b-col>
        <b-col>
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Facebook Link"
          >
            <label for="wildcard">Facebook Link</label>
            <b-form-input
              id="facebookInfoPageUrl"
              v-model="data.facebookInfo.pageUrl"
              :disabled="!data.facebookInfo.enabled"
              :state="errors.length > 0 ? false : null"
              placeholder="Facebook Link"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col cols="1">
          <b-form-checkbox
            v-model="data.facebookInfo.enabled"
            checked="true"
            name="check-button"
            switch
            inline
          />
        </b-col>
      </b-row>
      <b-row
        v-if="data.instagramInfo"
        align-v="end"
      >
        <b-col cols="1">
          <b-img
            style="width: 32px; height: 32px"
            fluid
            :src="data.instagramInfo.iconUrl"
            alt=" "
          />
        </b-col>
        <b-col>
          <label for="wildcard">Instagram Icon</label>
          <b-form-file
            ref="file"
            v-model="image"
            type="file"
            accept="image/*"
            :disabled="!data.instagramInfo.enabled"
            @input="upload(image, 'instagramInfo')"
          />
        </b-col>
        <b-col cols="1">
          <b-button
            variant="outline-primary"
            title="Clear Image"
            :disabled="!data.instagramInfo.enabled"
            @click="clearImage('instagramInfo')"
          >
            <b-icon
              icon="eraser"
              aria-hidden="true"
            />
          </b-button>
        </b-col>
        <b-col>
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Instagram Link"
          >
            <label for="wildcard">Instagram Link</label>
            <b-form-input
              id="instagramInfoPageUrl"
              v-model="data.instagramInfo.pageUrl"
              :disabled="!data.instagramInfo.enabled"
              :state="errors.length > 0 ? false : null"
              placeholder="Instagram Link"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col cols="1">
          <b-form-checkbox
            v-model="data.instagramInfo.enabled"
            checked="true"
            name="check-button"
            switch
            inline
          />
        </b-col>
      </b-row>
			<b-row
				v-if="data.snapchatInfo"
				align-v="end"
			>
				<b-col cols="1">
					<b-img
						style="width: 32px; height: 32px"
						fluid
						:src="data.snapchatInfo.iconUrl"
						alt=" "
					/>
				</b-col>
				<b-col>
					<label for="wildcard">Snapchat Icon</label>
					<b-form-file
						ref="file"
						v-model="image"
						type="file"
						accept="image/*"
						:disabled="!data.snapchatInfo.enabled"
						@input="upload(image, 'snapchatInfo')"
					/>
				</b-col>
				<b-col cols="1">
					<b-button
						variant="outline-primary"
						title="Clear Image"
						:disabled="!data.snapchatInfo.enabled"
						@click="clearImage('snapchatInfo')"
					>
						<b-icon
							icon="eraser"
							aria-hidden="true"
						/>
					</b-button>
				</b-col>
				<b-col>
					<validation-provider
						#default="{ errors }"
						rules="required"
						name="Snapchat Link"
					>
						<label for="wildcard">Snapchat Link</label>
						<b-form-input
							id="snapchatInfoPageUrl"
							v-model="data.snapchatInfo.pageUrl"
							:disabled="!data.snapchatInfo.enabled"
							:state="errors.length > 0 ? false : null"
							placeholder="Snapchat Link"
						/>
						<small class="text-danger">{{ errors[0] }}</small>
					</validation-provider>
				</b-col>
				<b-col cols="1">
					<b-form-checkbox
						v-model="data.snapchatInfo.enabled"
						checked="true"
						name="check-button"
						switch
						inline
					/>
				</b-col>
			</b-row>
			<b-row
				v-if="data.tiktokInfo"
				align-v="end"
			>
				<b-col cols="1">
					<b-img
						style="width: 32px; height: 32px"
						fluid
						:src="data.tiktokInfo.iconUrl"
						alt=" "
					/>
				</b-col>
				<b-col>
					<label for="wildcard">Tiktok Icon</label>
					<b-form-file
						ref="file"
						v-model="image"
						type="file"
						accept="image/*"
						:disabled="!data.tiktokInfo.enabled"
						@input="upload(image, 'tiktokInfo')"
					/>
				</b-col>
				<b-col cols="1">
					<b-button
						variant="outline-primary"
						title="Clear Image"
						:disabled="!data.tiktokInfo.enabled"
						@click="clearImage('tiktokInfo')"
					>
						<b-icon
							icon="eraser"
							aria-hidden="true"
						/>
					</b-button>
				</b-col>
				<b-col>
					<validation-provider
						#default="{ errors }"
						rules="required"
						name="Tiktok Link"
					>
						<label for="wildcard">Tiktok Link</label>
						<b-form-input
							id="tiktokInfoPageUrl"
							v-model="data.tiktokInfo.pageUrl"
							:disabled="!data.tiktokInfo.enabled"
							:state="errors.length > 0 ? false : null"
							placeholder="Tiktok Link"
						/>
						<small class="text-danger">{{ errors[0] }}</small>
					</validation-provider>
				</b-col>
				<b-col cols="1">
					<b-form-checkbox
						v-model="data.tiktokInfo.enabled"
						checked="true"
						name="check-button"
						switch
						inline
					/>
				</b-col>
			</b-row>
    </b-card-form>
    <b-row>
      <b-col class="text-right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="save(false)"
        >
          <span>Save</span>
        </b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import BCardForm from '@/components/b-card-form'
import {
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BFormFile,
  BButton,
  BImg,
  BFormCheckbox,
  BIcon,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import base64Encode from '@/libs/UploadFilesService'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormFile,
    BButton,
    BImg,
    BFormCheckbox,
    BIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        linkedInInfo: {},
        twitterInfo: {},
        facebookInfo: {},
        instagramInfo: {},
				snapchatInfo: {},
				tiktokInfo: {},
      },
      image: null,
      progress: 0,
      required,
    }
  },
  watch: {
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.data.mainPhotoUrl = URL.createObjectURL(newValue)

          this.progress = 0
          base64Encode(newValue).then(value => {
            const config = { headers: { 'Content-Type': 'application/json' } }
            this.$http
              .post('/uploads', JSON.stringify(value), config)
              .then(response => {
                this.data.mainPhotoUrl = response.data
              })
          })
        }
      }
    },
  },
  created() {
    // data
    this.$http.get('/ContactUs/customersupportinformation').then(response => {
      this.data = response.data
    })
  },
  methods: {
    save(isPublish){
      const valid = this.$refs.validationObserver
        .validate()
        .then(success => {
          if (success) {
            this.$http.put('/ContactUs/customersupportinformation', this.data).then(()=>{
                if(!isPublish)
                {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Save Successfully",
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    },
                  });
                }
              })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Check the feilds validations above to be able to save!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
          return success
        })
      return valid
    },
    upload(image, item) {
      this.data[item].iconUrl = URL.createObjectURL(image)

      this.progress = 0
      base64Encode(image).then(value => {
        const config = { headers: { 'Content-Type': 'application/json' } }
        this.$http
          .post('/uploads', JSON.stringify(value), config)
          .then(response => {
            this.data[item].iconUrl = response.data
          })
      })
    },
    clearImage(item) {
      this.data[item].iconUrl = ''
    },
    selectImage() {
      // this.currentImage = this.$refs.file.files.item(0)
      // console.log(this.image)
      // this.data.mainPhotoUrl = URL.createObjectURL(this.currentImage)
      // this.progress = 0
      // this.message = ''
    },
  },
}
</script>
