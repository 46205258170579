/* eslint-disable vue/valid-v-model */
<template>
  <validation-observer ref="validationObserver">
    <b-card-form title="Section Title">
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >
          <b-form-group
            label="Title English"
            label-for="customerSupportTitleEn"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Title English"
            >
              <b-form-input
                id="customerSupportTitleEn"
                v-model="data.titleEn"
                :state="errors.length > 0 ? false : null"
                placeholder="Title English"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
          dir="rtl"
        >
          <b-form-group
            label="Title Arabic"
            label-for="customerSupportTitleAr"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Title Arabic"
            >
              <b-form-input
                id="customerSupportTitleAr"
                v-model="data.titleAr"
                :state="errors.length > 0 ? false : null"
                placeholder="Title Arabic"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >
          <b-form-group
            label="Subtitle English"
            label-for="customerSupportSubTitleEn"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Subtitle English"
            >
              <b-form-input
                id="customerSupportSubTitleEn"
                v-model="data.subTitleEn"
                :state="errors.length > 0 ? false : null"
                placeholder="Subtitle English"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
          dir="rtl"
        >
          <b-form-group
            label="Subtitle Arabic"
            label-for="customerSupportSubTitleAr"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Subtitle Arabic"
            >
              <b-form-input
                id="customerSupportSubTitleAr"
                v-model="data.subTitleAr"
                :state="errors.length > 0 ? false : null"
                placeholder="Subtitle Arabic"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-form>
    <b-card-form title="Offices">
      <b-row
        v-for="(item, index) in data.offices"
        :id="item.id"
        :key="item.id"
        ref="row"
      >
        <b-col cols="12">
          <b-row>
            <!-- Item Name -->
            <b-col
              md="4"
              xl="5"
            >
              <b-form-group
                label="Location English"
                label-for="locationEn"
              >
                <validation-provider
                  #default="{ errors }"
                  :vid="'locationEn' + item.id"
                  rules="required"
                  name="Location English"
                >
                  <b-form-input
                    id="locationEn"
                    v-model="item.locationEn"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Location English"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Item Name -->
            <b-col
              md="4"
              xl="5"
              dir="rtl"
            >
              <b-form-group
                label="Location Arabic"
                label-for="locationAr"
              >
                <validation-provider
                  #default="{ errors }"
                  :vid="'titleEn' + item.id"
                  rules="required"
                  name="Location Arabic"
                >
                  <b-form-input
                    id="locationAr"
                    v-model="item.locationAr"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Location Arabic"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Remove Button -->
            <b-col
              lg="2"
              md="3"
              class="mb-50"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>Delete</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col
              md="4"
              xl="10"
            >
              <app-collapse
                accordion
                type="border"
              >
                <app-collapse-item
                  title="Phone Numbers"
                  is-visible
                >
                  <b-row
                    v-for="(phone, indexPhones) in item.phones"
                    :id="phone.value"
                    :key="phone.value"
                  >
                    <!-- Item Name -->
                    <b-col
                      md="4"
                      xl="10"
                    >
                      <b-form-group
                        label="Phone Number"
                        label-for="phone"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :vid="'phone' + phone.value"
                          rules="required"
                          name="Phone Number"
                        >
                          <b-form-input
                            id="phone"
                            v-model="phone.text"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Phone Number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Remove Button -->
                    <b-col
                      lg="2"
                      md="3"
                      class="mb-50"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeItemPhones(index,indexPhones)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="repeateAgainPhones(index)"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                      />
                      <span>Add New</span>
                    </b-button>
                  </b-col>
                </app-collapse-item>
                <app-collapse-item title="Emails">
                  <b-row
                    v-for="(email, indexEmail) in item.emails"
                    :id="email.value"
                    :key="email.value"
                  >
                    <!-- Item Name -->
                    <b-col
                      md="4"
                      xl="10"
                    >
                      <b-form-group
                        label="Email"
                        label-for="email"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :vid="'email' + email.value"
                          rules="required"
                          name="Email"
                        >
                          <b-form-input
                            id="email"
                            v-model="email.text"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Email"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Remove Button -->
                    <b-col
                      lg="2"
                      md="3"
                      class="mb-50"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeItemEmails(indexEmail)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="repeateAgainEmails(index)"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                      />
                      <span>Add New</span>
                    </b-button>
                  </b-col>
                </app-collapse-item>
              </app-collapse>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="4"
              xl="5"
            >
              <b-form-group
                label="Working Days English"
                label-for="workingDaysEn"
              >
                <validation-provider
                  #default="{ errors }"
                  :vid="'workingDaysEn' + item.id"
                  rules="required"
                  name="Working Days English"
                >
                  <b-form-input
                    id="workingDaysEn"
                    v-model="item.workingDaysEn"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Working Days English"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Item Name -->
            <b-col
              md="4"
              xl="5"
              dir="rtl"
            >
              <b-form-group
                label="Working Days Arabic"
                label-for="workingDaysAr"
              >
                <validation-provider
                  #default="{ errors }"
                  :vid="'workingDaysAr' + item.id"
                  rules="required"
                  name="Working Days Arabic"
                >
                  <b-form-input
                    id="workingDaysAr"
                    v-model="item.workingDaysAr"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Working Days Arabic"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="4"
              xl="5"
            >
              <b-form-group
                label="Working Hours English"
                label-for="workingHoursEn"
              >
                <validation-provider
                  #default="{ errors }"
                  :vid="'workingHoursEn' + item.id"
                  rules="required"
                  name="Working Hours English"
                >
                  <b-form-input
                    id="workingHoursEn"
                    v-model="item.workingHoursEn"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Working Hours English"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Item Name -->
            <b-col
              md="4"
              xl="5"
              dir="rtl"
            >
              <b-form-group
                label="Working Hours Arabic"
                label-for="workingHoursAr"
              >
                <validation-provider
                  #default="{ errors }"
                  :vid="'workingHoursAr' + item.id"
                  rules="required"
                  name="Working Hours Arabic"
                >
                  <b-form-input
                    id="workingHoursAr"
                    v-model="item.workingHoursAr"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Working Hours Arabic"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="4"
              xl="5"
            >
              <b-form-group
                label="Address English"
                label-for="addressEn"
              >
                <validation-provider
                  #default="{ errors }"
                  :vid="'addressEn' + item.id"
                  rules="required"
                  name="Address English"
                >
                  <b-form-input
                    id="addressEn"
                    v-model="item.addressEn"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Address English"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Item Name -->
            <b-col
              md="4"
              xl="5"
              dir="rtl"
            >
              <b-form-group
                label="Address Arabic"
                label-for="addressAr"
              >
                <validation-provider
                  #default="{ errors }"
                  :vid="'addressAr' + item.id"
                  rules="required"
                  name="Address Arabic"
                >
                  <b-form-input
                    id="addressAr"
                    v-model="item.addressAr"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Address Arabic"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
    </b-card-form>
    <b-row>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Add New</span>
        </b-button>
      </b-col>
      <b-col class="text-right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="save(false)"
        >
          <span>Save</span>
        </b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import BCardForm from '@/components/b-card-form'
import {
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import base64Encode from '@/libs/UploadFilesService'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import UUID from '@/libs/uuid'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    BCardForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        offices: [],
      },
      image: null,
      progress: 0,
      required,
    }
  },
  watch: {
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.data.mainPhotoUrl = URL.createObjectURL(newValue)

          this.progress = 0
          base64Encode(newValue).then(value => {
            const config = { headers: { 'Content-Type': 'application/json' } }
            this.$http
              .post('/uploads', JSON.stringify(value), config)
              .then(response => {
                this.data.mainPhotoUrl = response.data
              })
          })
        }
      }
    },
  },
  created() {
    // data
    this.$http.get('/ContactUs/officeinfo').then(response => {
      this.data = response.data
    })
  },
  methods: {
    save(isPublish){
      const valid = this.$refs.validationObserver
        .validate()
        .then(success => {
          if (success) {
            this.$http.put('/ContactUs/officeinfo', this.data).then(()=>{
                if(!isPublish)
                {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Save Successfully",
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    },
                  });
                }
              })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Check the feilds validations above to be able to save!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
          return success
        })
      return valid
    },
    repeateAgain() {
      this.data.offices.push({
        id: UUID(),
        phones: [],
        emails: [],
      })
    },
    removeItem(index) {
      this.data.offices.splice(index, 1)
    },
    repeateAgainPhones(officeindex) {
      this.data.offices[officeindex].phones.push({
        id: UUID(),
      })
    },
    removeItemPhones(officeindex, index) {
      this.data.offices[officeindex].phones.splice(index, 1)
    },
    repeateAgainEmails(officeindex) {
      this.data.offices[officeindex].emails.push({
        id: UUID(),
      })
    },
    removeItemEmails(officeindex, index) {
      this.data.offices[officeindex].emails.splice(index, 1)
    },
    selectImage() {
      // this.currentImage = this.$refs.file.files.item(0)
      // console.log(this.image)
      // this.data.mainPhotoUrl = URL.createObjectURL(this.currentImage)
      // this.progress = 0
      // this.message = ''
    },
  },
}
</script>
