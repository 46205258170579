var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validationObserver"},[_c('b-card-form',{attrs:{"title":"Customer Support Infomation"}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Title English","label-for":"customerSupportTitleEn"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Title English"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"customerSupportTitleEn","state":errors.length > 0 ? false : null,"placeholder":"Title English"},model:{value:(_vm.data.customerSupportTitleEn),callback:function ($$v) {_vm.$set(_vm.data, "customerSupportTitleEn", $$v)},expression:"data.customerSupportTitleEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6","dir":"rtl"}},[_c('b-form-group',{attrs:{"label":"Title Arabic","label-for":"customerSupportTitleAr"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Title Arabic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"customerSupportTitleAr","state":errors.length > 0 ? false : null,"placeholder":"Title Arabic"},model:{value:(_vm.data.customerSupportTitleAr),callback:function ($$v) {_vm.$set(_vm.data, "customerSupportTitleAr", $$v)},expression:"data.customerSupportTitleAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Subtitle English","label-for":"customerSupportSubTitleEn"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Subtitle English"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"customerSupportSubTitleEn","state":errors.length > 0 ? false : null,"placeholder":"Subtitle English"},model:{value:(_vm.data.customerSupportSubTitleEn),callback:function ($$v) {_vm.$set(_vm.data, "customerSupportSubTitleEn", $$v)},expression:"data.customerSupportSubTitleEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6","dir":"rtl"}},[_c('b-form-group',{attrs:{"label":"Subtitle Arabic","label-for":"customerSupportSubTitleAr"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Subtitle Arabic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"customerSupportSubTitleAr","state":errors.length > 0 ? false : null,"placeholder":"Subtitle Arabic"},model:{value:(_vm.data.customerSupportSubTitleAr),callback:function ($$v) {_vm.$set(_vm.data, "customerSupportSubTitleAr", $$v)},expression:"data.customerSupportSubTitleAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"customerSupportEmail"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"customerSupportEmail","state":errors.length > 0 ? false : null,"placeholder":"Email"},model:{value:(_vm.data.customerSupportEmail),callback:function ($$v) {_vm.$set(_vm.data, "customerSupportEmail", $$v)},expression:"data.customerSupportEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"customerSupportPhone"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Phone Number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"customerSupportPhone","state":errors.length > 0 ? false : null,"placeholder":"Phone Number"},model:{value:(_vm.data.customerSupportPhone),callback:function ($$v) {_vm.$set(_vm.data, "customerSupportPhone", $$v)},expression:"data.customerSupportPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-card-form',{attrs:{"title":"FAQs"}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Title English","label-for":"faqsTitleEn"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Title English"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"faqsTitleEn","state":errors.length > 0 ? false : null,"placeholder":"Title English"},model:{value:(_vm.data.faqsTitleEn),callback:function ($$v) {_vm.$set(_vm.data, "faqsTitleEn", $$v)},expression:"data.faqsTitleEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6","dir":"rtl"}},[_c('b-form-group',{attrs:{"label":"Title Arabic","label-for":"faqsTitleAr"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Title Arabic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"faqsTitleAr","state":errors.length > 0 ? false : null,"placeholder":"Title Arabic"},model:{value:(_vm.data.faqsTitleAr),callback:function ($$v) {_vm.$set(_vm.data, "faqsTitleAr", $$v)},expression:"data.faqsTitleAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Subtitle English","label-for":"faqsSubTitleEn"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Subtitle English"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"faqsSubTitleEn","state":errors.length > 0 ? false : null,"placeholder":"Subtitle English"},model:{value:(_vm.data.faqsSubTitleEn),callback:function ($$v) {_vm.$set(_vm.data, "faqsSubTitleEn", $$v)},expression:"data.faqsSubTitleEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6","dir":"rtl"}},[_c('b-form-group',{attrs:{"label":"Subtitle Arabic","label-for":"faqsSubTitleAr"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Subtitle Arabic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"faqsSubTitleAr","state":errors.length > 0 ? false : null,"placeholder":"Subtitle Arabic"},model:{value:(_vm.data.faqsSubTitleAr),callback:function ($$v) {_vm.$set(_vm.data, "faqsSubTitleAr", $$v)},expression:"data.faqsSubTitleAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"FAQs Linked Page Url","label-for":"faqsLinkedPageUrl"}},[_c('validation-provider',{attrs:{"rules":"required","name":"FAQs Linked Page Url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"faqsLinkedPageUrl","state":errors.length > 0 ? false : null,"placeholder":"FAQs Linked Page Url"},model:{value:(_vm.data.faqsLinkedPageUrl),callback:function ($$v) {_vm.$set(_vm.data, "faqsLinkedPageUrl", $$v)},expression:"data.faqsLinkedPageUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-card-form',{attrs:{"title":"Social Media "}},[(_vm.data.linkedInInfo)?_c('b-row',{attrs:{"align-v":"end"}},[_c('b-col',{attrs:{"cols":"1"}},[_c('b-img',{staticStyle:{"width":"32px","height":"32px"},attrs:{"fluid":"","src":_vm.data.linkedInInfo.iconUrl,"alt":" "}})],1),_c('b-col',[_c('label',{attrs:{"for":"wildcard"}},[_vm._v("Linkedin Icon")]),_c('b-form-file',{ref:"file",attrs:{"type":"file","accept":"image/*","disabled":!_vm.data.linkedInInfo.enabled},on:{"input":function($event){return _vm.upload(_vm.image, 'linkedInInfo')}},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-button',{attrs:{"variant":"outline-primary","title":"Clear Image","disabled":!_vm.data.linkedInInfo.enabled},on:{"click":function($event){return _vm.clearImage('linkedInInfo')}}},[_c('b-icon',{attrs:{"icon":"eraser","aria-hidden":"true"}})],1)],1),_c('b-col',[_c('validation-provider',{attrs:{"rules":"required","name":"Linkedin Link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"wildcard"}},[_vm._v("Linkedin Link")]),_c('b-form-input',{attrs:{"id":"linkedInInfoPageUrl","disabled":!_vm.data.linkedInInfo.enabled,"state":errors.length > 0 ? false : null,"placeholder":"Linkedin Link"},model:{value:(_vm.data.linkedInInfo.pageUrl),callback:function ($$v) {_vm.$set(_vm.data.linkedInInfo, "pageUrl", $$v)},expression:"data.linkedInInfo.pageUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1226040610)})],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-checkbox',{attrs:{"checked":"true","name":"check-button","switch":"","inline":""},model:{value:(_vm.data.linkedInInfo.enabled),callback:function ($$v) {_vm.$set(_vm.data.linkedInInfo, "enabled", $$v)},expression:"data.linkedInInfo.enabled"}})],1)],1):_vm._e(),(_vm.data.twitterInfo)?_c('b-row',{attrs:{"align-v":"end"}},[_c('b-col',{attrs:{"cols":"1"}},[_c('b-img',{staticStyle:{"width":"32px","height":"32px"},attrs:{"fluid":"","src":_vm.data.twitterInfo.iconUrl,"alt":" "}})],1),_c('b-col',[_c('label',{attrs:{"for":"wildcard"}},[_vm._v("Twitter Icon")]),_c('b-form-file',{ref:"file",attrs:{"type":"file","accept":"image/*","disabled":!_vm.data.twitterInfo.enabled},on:{"input":function($event){return _vm.upload(_vm.image, 'twitterInfo')}},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-button',{attrs:{"variant":"outline-primary","title":"Clear Image","disabled":!_vm.data.twitterInfo.enabled},on:{"click":function($event){return _vm.clearImage('twitterInfo')}}},[_c('b-icon',{attrs:{"icon":"eraser","aria-hidden":"true"}})],1)],1),_c('b-col',[_c('validation-provider',{attrs:{"rules":"required","name":"Twitter Link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"wildcard"}},[_vm._v("Twitter Link")]),_c('b-form-input',{attrs:{"id":"twitterInfoPageUrl","disabled":!_vm.data.twitterInfo.enabled,"state":errors.length > 0 ? false : null,"placeholder":"Twitter Link"},model:{value:(_vm.data.twitterInfo.pageUrl),callback:function ($$v) {_vm.$set(_vm.data.twitterInfo, "pageUrl", $$v)},expression:"data.twitterInfo.pageUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,214617753)})],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-checkbox',{attrs:{"checked":"true","name":"check-button","switch":"","inline":""},model:{value:(_vm.data.twitterInfo.enabled),callback:function ($$v) {_vm.$set(_vm.data.twitterInfo, "enabled", $$v)},expression:"data.twitterInfo.enabled"}})],1)],1):_vm._e(),(_vm.data.facebookInfo)?_c('b-row',{attrs:{"align-v":"end"}},[_c('b-col',{attrs:{"cols":"1"}},[_c('b-img',{staticStyle:{"width":"32px","height":"32px"},attrs:{"fluid":"","src":_vm.data.facebookInfo.iconUrl,"alt":" "}})],1),_c('b-col',[_c('label',{attrs:{"for":"wildcard"}},[_vm._v("Facebook Icon")]),_c('b-form-file',{ref:"file",attrs:{"type":"file","accept":"image/*","disabled":!_vm.data.facebookInfo.enabled},on:{"input":function($event){return _vm.upload(_vm.image, 'facebookInfo')}},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-button',{attrs:{"variant":"outline-primary","title":"Clear Image","disabled":!_vm.data.facebookInfo.enabled},on:{"click":function($event){return _vm.clearImage('facebookInfo')}}},[_c('b-icon',{attrs:{"icon":"eraser","aria-hidden":"true"}})],1)],1),_c('b-col',[_c('validation-provider',{attrs:{"rules":"required","name":"Facebook Link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"wildcard"}},[_vm._v("Facebook Link")]),_c('b-form-input',{attrs:{"id":"facebookInfoPageUrl","disabled":!_vm.data.facebookInfo.enabled,"state":errors.length > 0 ? false : null,"placeholder":"Facebook Link"},model:{value:(_vm.data.facebookInfo.pageUrl),callback:function ($$v) {_vm.$set(_vm.data.facebookInfo, "pageUrl", $$v)},expression:"data.facebookInfo.pageUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1259523084)})],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-checkbox',{attrs:{"checked":"true","name":"check-button","switch":"","inline":""},model:{value:(_vm.data.facebookInfo.enabled),callback:function ($$v) {_vm.$set(_vm.data.facebookInfo, "enabled", $$v)},expression:"data.facebookInfo.enabled"}})],1)],1):_vm._e(),(_vm.data.instagramInfo)?_c('b-row',{attrs:{"align-v":"end"}},[_c('b-col',{attrs:{"cols":"1"}},[_c('b-img',{staticStyle:{"width":"32px","height":"32px"},attrs:{"fluid":"","src":_vm.data.instagramInfo.iconUrl,"alt":" "}})],1),_c('b-col',[_c('label',{attrs:{"for":"wildcard"}},[_vm._v("Instagram Icon")]),_c('b-form-file',{ref:"file",attrs:{"type":"file","accept":"image/*","disabled":!_vm.data.instagramInfo.enabled},on:{"input":function($event){return _vm.upload(_vm.image, 'instagramInfo')}},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-button',{attrs:{"variant":"outline-primary","title":"Clear Image","disabled":!_vm.data.instagramInfo.enabled},on:{"click":function($event){return _vm.clearImage('instagramInfo')}}},[_c('b-icon',{attrs:{"icon":"eraser","aria-hidden":"true"}})],1)],1),_c('b-col',[_c('validation-provider',{attrs:{"rules":"required","name":"Instagram Link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"wildcard"}},[_vm._v("Instagram Link")]),_c('b-form-input',{attrs:{"id":"instagramInfoPageUrl","disabled":!_vm.data.instagramInfo.enabled,"state":errors.length > 0 ? false : null,"placeholder":"Instagram Link"},model:{value:(_vm.data.instagramInfo.pageUrl),callback:function ($$v) {_vm.$set(_vm.data.instagramInfo, "pageUrl", $$v)},expression:"data.instagramInfo.pageUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3618600924)})],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-checkbox',{attrs:{"checked":"true","name":"check-button","switch":"","inline":""},model:{value:(_vm.data.instagramInfo.enabled),callback:function ($$v) {_vm.$set(_vm.data.instagramInfo, "enabled", $$v)},expression:"data.instagramInfo.enabled"}})],1)],1):_vm._e(),(_vm.data.snapchatInfo)?_c('b-row',{attrs:{"align-v":"end"}},[_c('b-col',{attrs:{"cols":"1"}},[_c('b-img',{staticStyle:{"width":"32px","height":"32px"},attrs:{"fluid":"","src":_vm.data.snapchatInfo.iconUrl,"alt":" "}})],1),_c('b-col',[_c('label',{attrs:{"for":"wildcard"}},[_vm._v("Snapchat Icon")]),_c('b-form-file',{ref:"file",attrs:{"type":"file","accept":"image/*","disabled":!_vm.data.snapchatInfo.enabled},on:{"input":function($event){return _vm.upload(_vm.image, 'snapchatInfo')}},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-button',{attrs:{"variant":"outline-primary","title":"Clear Image","disabled":!_vm.data.snapchatInfo.enabled},on:{"click":function($event){return _vm.clearImage('snapchatInfo')}}},[_c('b-icon',{attrs:{"icon":"eraser","aria-hidden":"true"}})],1)],1),_c('b-col',[_c('validation-provider',{attrs:{"rules":"required","name":"Snapchat Link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"wildcard"}},[_vm._v("Snapchat Link")]),_c('b-form-input',{attrs:{"id":"snapchatInfoPageUrl","disabled":!_vm.data.snapchatInfo.enabled,"state":errors.length > 0 ? false : null,"placeholder":"Snapchat Link"},model:{value:(_vm.data.snapchatInfo.pageUrl),callback:function ($$v) {_vm.$set(_vm.data.snapchatInfo, "pageUrl", $$v)},expression:"data.snapchatInfo.pageUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1945752726)})],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-checkbox',{attrs:{"checked":"true","name":"check-button","switch":"","inline":""},model:{value:(_vm.data.snapchatInfo.enabled),callback:function ($$v) {_vm.$set(_vm.data.snapchatInfo, "enabled", $$v)},expression:"data.snapchatInfo.enabled"}})],1)],1):_vm._e(),(_vm.data.tiktokInfo)?_c('b-row',{attrs:{"align-v":"end"}},[_c('b-col',{attrs:{"cols":"1"}},[_c('b-img',{staticStyle:{"width":"32px","height":"32px"},attrs:{"fluid":"","src":_vm.data.tiktokInfo.iconUrl,"alt":" "}})],1),_c('b-col',[_c('label',{attrs:{"for":"wildcard"}},[_vm._v("Tiktok Icon")]),_c('b-form-file',{ref:"file",attrs:{"type":"file","accept":"image/*","disabled":!_vm.data.tiktokInfo.enabled},on:{"input":function($event){return _vm.upload(_vm.image, 'tiktokInfo')}},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-button',{attrs:{"variant":"outline-primary","title":"Clear Image","disabled":!_vm.data.tiktokInfo.enabled},on:{"click":function($event){return _vm.clearImage('tiktokInfo')}}},[_c('b-icon',{attrs:{"icon":"eraser","aria-hidden":"true"}})],1)],1),_c('b-col',[_c('validation-provider',{attrs:{"rules":"required","name":"Tiktok Link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"wildcard"}},[_vm._v("Tiktok Link")]),_c('b-form-input',{attrs:{"id":"tiktokInfoPageUrl","disabled":!_vm.data.tiktokInfo.enabled,"state":errors.length > 0 ? false : null,"placeholder":"Tiktok Link"},model:{value:(_vm.data.tiktokInfo.pageUrl),callback:function ($$v) {_vm.$set(_vm.data.tiktokInfo, "pageUrl", $$v)},expression:"data.tiktokInfo.pageUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2164154882)})],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-checkbox',{attrs:{"checked":"true","name":"check-button","switch":"","inline":""},model:{value:(_vm.data.tiktokInfo.enabled),callback:function ($$v) {_vm.$set(_vm.data.tiktokInfo, "enabled", $$v)},expression:"data.tiktokInfo.enabled"}})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save(false)}}},[_c('span',[_vm._v("Save")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }