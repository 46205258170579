<template>
  <b-tabs>
    <b-tab active>
      <template #title>
        <feather-icon icon="HomeIcon" />
        <span>Title</span>
      </template>

      <contact-us-title ref="title" />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="ToolIcon" />
        <span>Contact Support</span>
      </template>

      <contact-us-contact-support ref="contactsupport" />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="EyeOffIcon" />
        <span>Form</span>
      </template>

      <contact-us-form ref="form" />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="EyeOffIcon" />
        <span>Office Info</span>
      </template>

      <contact-us-office-info ref="officeinfo" />
    </b-tab>
    <!-- New Tab Button (Using tabs-end slot) -->
    <template #tabs-end>
      <div class="ml-auto">
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              right
              @click.prevent="preview"
            >
              Preview
            </b-button>
          </b-col>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              right
              @click.prevent="publish"
            >
              Publish
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab, BButton, BCol, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ContactUsTitle from './ContactUsTitle.vue'
import ContactUsContactSupport from './ContactUsContactSupport.vue'
import ContactUsForm from './ContactUsForm.vue'
import ContactUsOfficeInfo from './ContactUsOfficeInfo'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BCol,
    BRow,
    ContactUsTitle,
    ContactUsContactSupport,
    ContactUsForm,
    ContactUsOfficeInfo,
  },
  directives: {
    Ripple,
  },
  methods: {
    preview() {
      Promise.all([
        this.$refs.title.save(true),
        this.$refs.contactsupport.save(true),
        this.$refs.form.save(true)]).then(values => {
        if (values.every(element => element === true)) {
          window.open(`${process.env.VUE_APP_PREVIEW_URL}contact-us?preview=true`)
        }
      })
    },
    async publish() {
      await  Promise.all([
        this.$refs.title.save(true),
        this.$refs.contactsupport.save(true),
        this.$refs.form.save(true)])
      await this.$http.post('/ContactUs/publish')

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Published Successfully',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });
    },
  },
}
</script>
