<template>
  <validation-observer ref="validationObserver">
    <b-card-form>
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >
          <b-form-group
            label="Title English"
            label-for="titleEn"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Title English"
            >
              <b-form-input
                id="titleEn"
                v-model="data.titleEn"
                :state="errors.length > 0 ? false:null"
                placeholder="Title English"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
          dir="rtl"
        >
          <b-form-group
            label="Title Arabic"
            label-for="titleAr"
          >

            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Title Arabic"
            >
              <b-form-input
                id="titleAr"
                v-model="data.titleAr"
                :state="errors.length > 0 ? false:null"
                placeholder="Title Arabic"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >
          <b-form-group
            label="Subtitle English"
            label-for="subtitleEn"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Subtitle English"
            >
              <b-form-input
                id="subtitleEn"
                v-model="data.subTitleEn"
                :state="errors.length > 0 ? false:null"
                placeholder="Subtitle English"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
          dir="rtl"
        >
          <b-form-group
            label="Subtitle Arabic"
            label-for="subtitleAr"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Subtitle Arabic"
            >
              <b-form-input
                id="subtitleAr"
                v-model="data.subTitleAr"
                :state="errors.length > 0 ? false:null"
                placeholder="Subtitle Arabic"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="save(false)"
          >
            <span>Save</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-form>
  </validation-observer>
</template>

<script>
import BCardForm from '@/components/b-card-form'
import {
  BFormInput, BRow, BCol, BFormGroup, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import base64Encode from '@/libs/UploadFilesService'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        showAddressesSection: true,
      },
      image: null,
      progress: 0,
      required,
    }
  },
  watch: {
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.data.mainPhotoUrl = URL.createObjectURL(newValue)

          this.progress = 0
          base64Encode(newValue)
            .then(value => {
              const config = { headers: { 'Content-Type': 'application/json' } }
              this.$http.post('/uploads', JSON.stringify(value), config)
                .then(response => {
                  this.data.mainPhotoUrl = response.data
                })
            })
        }
      }
    },
  },
  created() {
    // data
    this.$http.get('/ContactUs/form')
      .then(response => {
        this.data = response.data
      })
  },
  methods: {
    save(isPublish){
      const valid = this.$refs.validationObserver.validate().then(success => {
        if (success) {
          this.$http.put('/ContactUs/form', this.data).then(()=>{
                if(!isPublish)
                {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Save Successfully",
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    },
                  });
                }
              })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Check the feilds validations above to be able to save!',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
        return success
      })
      return valid
    },
    selectImage() {
      // this.currentImage = this.$refs.file.files.item(0)
      // console.log(this.image)
      // this.data.mainPhotoUrl = URL.createObjectURL(this.currentImage)
      // this.progress = 0
      // this.message = ''
    },
  },
}
</script>
